import CoreConstants from "../constants/CoreConstants";
import {getCookie, getCookieLogin} from "./Cookie";
import {confirmAlert} from "react-confirm-alert";


/**
 * Handle error exception
 *
 * @param Error error
 * @param Function/null callback
 */
const handleException = (error, callback = null) => {
    console.error(error);

    // to do
}

/**
 * Get contact's display name
 *
 * @param string name
 * @param string number
 * @param string email
 * @returns string
 */
const getContactDisplayName = (name, number, email) => {
    if (name === '' || name === ' ' || name === null || name === 'null null') {
        if (number === '' || number === ' ') {
            if (email === '' || email === ' ') {
                return '[No Name]';
            } else {
                return email.length > 15 ? email.substr(0, 13)+' ...' : email;
            }
        } else {
            return number;
        }
    }

    return name.length > 15 ? name.substr(0, 13)+' ...' : name;
}

/**
 * Display notification
 *
 * @param string message
 * @param string type
 * @param string/null title
 * @param boolean sticky
 */
const showNotification = (message = 'Something went wrong', type = 'error', onClose = () => {return false}) => {
    confirmAlert({
        title: type,
        message: message,
        buttons: [
            {
                label: 'Close',
                onClick: () => onClose()
            }
        ]
    });
};



/**
 * Get initial of name
 *
 * @param string name
 * @returns string
 */
const getInitial = (name) => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = 'X'
            if(name == 'null null' || name == '' || name == ' '){
                fullNameInitial = 'X';
            }else if(name !== 'undefined' || name !== null){
                fullNameInitial = name.split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return 'X';
        }
    } catch (error) {
        return 'X';
    }
}

const checkEmptyJson = data => {
    if(JSON.stringify(data) == '{}'){
        return false
    }
    return true
}

const limitText = (text, limit = 40) => {
    if (text.length > limit) {
        return text.substring(0, limit - 3).trim() + "...";
    }
    return text;
}

const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {
    if(mobileNumber != '' && mobileNumber.length < 10){
        return false;
    }
    else if (mobileNumber != '' && mobileNumber.length > 11){
        return false
    }else if(mobileNumber.length == 11 && mobileNumber.substring(0,1) != 1){
        return false;
    }
    return true
}

const checkLetterExistInText = (text) => {
    var regExp = /[a-zA-Z]/g;
    let result = false
    if(regExp.test(text)){
        result = true
    }
    return result
}

const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phoneNumberString
}

const getTextForType= (type_for) => {
    switch (type_for) {
        case CoreConstants.SMS:
            return "OUTGOING SMS";

        case CoreConstants.MMS:
            return "OUTGOING MMS";

        case CoreConstants.VOICE:
            return "VOICE MAIL";

        case CoreConstants.EMAIL:
            return "EMAIL";

        case CoreConstants.VIRTUAL_NUMBER:
            return "VIRTUAL NUMBER";

        case CoreConstants.PACKAGE_BUY:
            return "PACKAGE BUY";

        case CoreConstants.PAY_AS_YOU_GO_BUY:
            return "PAY AS YOU GO BUY";

        case CoreConstants.PACKAGE_RENEW:
            return "PACKAGE RENEW";

        case CoreConstants.CREDIT_ADJUSTMENT:
            return "ADJUSTMENT";

        case CoreConstants.AUTO_RECHARGE:
            return "AUTO RECHARGE";

        case CoreConstants.ADDITIONAL_USER_BUY:
            return "ADDITIONAL USER BUY";

        case CoreConstants.ADMIN_INSTANT_TOPUP:
            return "ADMIN INSTANT TOPUP";

        case CoreConstants.INSTANT_TOPUP:
            return "INSTANT TOPUP";

        case CoreConstants.CALL:
            return "OUTGOING CALL";

        case CoreConstants.CALLBRIDGE:
            return "CALL BRIDGE";

        case CoreConstants.CALL_RECORD:
            return "CALL RECORD";

        case CoreConstants.POST_CARD:
            return "POSTCARD";

        case CoreConstants.GREETINGS_CARD:
            return "GREETING CARD";

        case CoreConstants.GIFT:
            return "GIFT";

        case CoreConstants.LETTER:
            return "LETTER";

        case CoreConstants.POWER_DIALER_CALL:
            return "POWER DIALER CALL";

        case CoreConstants.POWER_DIALER_CALL_RECORD:
            return "POWER DIALER CALL RECORD";
        case CoreConstants.A2P_USER_REGISTRATION:
            return "A2P USER REGISTRATION";

        case CoreConstants.A2P_CAMPAIGN_REGISTRATION:
            return "A2P CAMPAIGN REGISTRATION";

        case CoreConstants.A2P_CAMPAIGN_RENEW:
            return "A2P CAMPAIGN RENEW";

        case CoreConstants.INCOMING_SMS:
            return "INCOMING SMS";

        case CoreConstants.INCOMING_MMS:
            return "INCOMING MMS";

        case CoreConstants.INCOMING_CALL:
            return "INCOMING CALL";
        case CoreConstants.CARRIER_LOOKUP:
            return "CARRIER LOOKUP";
        case CoreConstants.CALLER_NAME_LOOKUP:
            return "CALLER NAME LOOKUP";
        case CoreConstants.EMAIL_LOOKUP:
            return "EMAIL LOOKUP";
        case CoreConstants.DNC:
            return "DNC";
        case CoreConstants.SKIP_TRACING:
            return "SKIP TRACING";

        case CoreConstants.SMS_CARRIER_FEES:
            return "SMS CARRIER FEES";

        case CoreConstants.CALL_CLIENT_CHARGE:
            return "CALL CLIENT CHARGE";

        case CoreConstants.CALL_GLOBAL_CONFERENCE_CHARGE:
            return "CALL GLOBAL CONFERENCE CHARGE";

        default:
            return ""
    }
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 */
export const getAccountData = (needle) => {
    // let accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if(check_new !== undefined){
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    }else{
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

export const getUserRole = ()=>{
    return getAccountData('userIsAgent') ? 3 : getAccountData('userIsOwner') ? 1  : getAccountData('userIsAdmin') ? 1 : ''

}

/**
 * Utility helper
 */
const Utils = {
    handleException,
    getContactDisplayName,
    getInitial,
    showNotification,
    checkEmptyJson,
    limitText,
    validateEmail,
    validateMobileNumber,
    checkLetterExistInText,
    formatPhoneNumber,
    getTextForType,
    getAccountData
}

export default Utils;