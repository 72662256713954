import * as ACTION from "../constants/billingActionTypes";
import React from "react";

const initialState = {
    billings: null,
    userPackage: null,
    userSettings: null,
    totalCredits: 0,
    user: null,
    isStarter: false,
    isTrial: false
};

const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_BILLINGS:
            return {...state, billings: action.payload}
        
        case ACTION.STORE_DETAILS:
            return {
                ...state, 
                userPackage: action.payload.userPackage,
                userSettings: action.payload.settings,
                totalCredits: action.payload.totalCredits,
                user: action.payload.user,
                isStarter: action.payload.isStarter,
                isTrial: action.payload.isTrial,
            }

        default:
            return state;
    }
}

export default billingReducer;
