import React, {useEffect, useRef, useState} from 'react';
import Styles from "./Style.module.css";
import {useHistory} from "react-router-dom";
import {Button, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {accountCancellation} from "../../api/billingApi";
import {deleteCookie} from "../../helpers/Cookie";


const reasons = [
    "I don't understand how to use the system",
    "You're missing a feature I need",
    "It's too expensive",
    "The system has too many bugs",
    "I'm switching to another system",
    "Other reason"
];

const FEATURELABEL="What feature are you looking for?";
const SYSTEMLABEL="What system are you switching to?";
const DEAFAULTLABEL="Please share any additional comments that could help improve our system.";
const IMAGELINK="https://s3.us-east-1.amazonaws.com/pypepro/user/1/S5QX7Pdl1VB46EquVO7OKp76oLAJHTRD5cTdxN1d.gif";

const App = (props) => {
    const history = useHistory();
    const noteRef = useRef(null);
    const [cancelling, setCancelling] = useState(false);
    const [cancellationReason, setCancellationReason] = useState(null);
    const [cancellationReasonAdditional, setCancellationReasonAdditional] = useState("");
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [additionalInfoLabel, setAdditionalInfoLabel] = useState("");

    useEffect(()=>{
        if (isRedirecting && isCancelled){
            setTimeout(()=>{
                logout();
            },2000);

            return () => {
                setIsCancelled(false);
                setIsRedirecting(false);
            }
        }
    },[isRedirecting, isCancelled]);

    const handleSubmitCancellation = () => {
        if (!cancellationReason){
            if(window.showNotification){
                window.showNotification("ERROR", "Must be select one reason");
                return;
            }
        }

        if (cancellationReason && cancellationReason == reasons[5] && !cancellationReasonAdditional){
            if(window.showNotification){
                window.showNotification("ERROR", "Must be write other reasons");
            }
            if (noteRef && noteRef.current){
                noteRef.current.focus();
            }
            return;
        }

        if (cancellationReason && cancellationReason == reasons[1] && !cancellationReasonAdditional){
            if(window.showNotification){
                window.showNotification("ERROR", "You must write the feature you are looking for.");
            }
            if (noteRef && noteRef.current){
                noteRef.current.focus();
            }
            return;
        }

        if (cancellationReason && cancellationReason == reasons[4] && !cancellationReasonAdditional){
            if(window.showNotification){
                window.showNotification("ERROR", "You must be write the name of the system you are switching to.");
            }
            if (noteRef && noteRef.current){
                noteRef.current.focus();
            }
            return;
        }

      try{
            let note = cancellationReason;
            if (cancellationReasonAdditional) {
                let additional = cancellationReasonAdditional;
                let label = '';
                if (cancellationReason === reasons[1]) {
                    label=(FEATURELABEL);
                } else if (cancellationReason === reasons[4]) {
                    label=(SYSTEMLABEL);
                } else {
                    label=(DEAFAULTLABEL);
                }
                note += `<br/><br/><div class='cancel-reason'><b>${label}</b></div><br/>${additional}`;
                setAdditionalInfoLabel(label);
              }

            
          setCancelling(true);

          accountCancellation({ note: note, cancelledBy: "Own" })
              .then((res)=>{
                 res = res.data;
                 if(res.status == 'success'){
                     if(window.showNotification){
                         window.showNotification("SUCCESS", res.html);
                     }
                     setIsRedirecting(true);
                     setIsCancelled(true);
                 }else {
                     if(window.showNotification){
                         window.showNotification("ERROR", res.html);
                     }
                 }
              }).catch((err)=>{
              if(window.showNotification){
                  window.showNotification("ERROR", "Something went wrong please try again.");
              }
          })
              .finally(()=>{
              setCancelling(false);
          });
      }catch (e){
          if(window.showNotification){
              window.showNotification("ERROR", "Something went wrong please try again.");
          }
      }
    };

    const logout = () => {
        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN);
        deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
        if(localStorage.getItem("global__custom__field__data")){
            localStorage.removeItem("global__custom__field__data");
        }
        history.push('/login');
        window.location.reload();
    }

    return (
        <div className={Styles.pageWrapper}>
            {
                isCancelled && isRedirecting ? (
                    <div className={Styles.cancelledContainer}>
                        <h1 className={Styles.cancelledTitle}>Account Cancellation Successful</h1>
                        <p className={Styles.cancelledMessage}>Your account has been successfully cancelled.</p>
                        <p className={Styles.cancelledMessageLogout}>You will be automatically logged out in a few seconds.</p>
                    </div>
                ) : (
                    <div className={Styles.innerPageWrapper}>
                        <img className={Styles.dontDoThisImage} src={IMAGELINK} alt="Don't do this gif."/>
                        <div className={Styles.selectOption}>
                            <h1 className={Styles.cancelReasonLabel}>Why are you cancelling? (Be brutally honest)</h1>
                            <RadioGroup className={Styles.selectOptions} aria-label="cancellationConfirmation" name="cancellationConfirmation" value={cancellationReason} onChange={(e)=>{setCancellationReason(e.target.value)}}>
                                {
                                    reasons.map((reason)=>(
                                        <FormControlLabel className={Styles.cancellationConfirmationAction} value={reason} control={<Radio />} label={reason} />
                                    ))
                                }
                            </RadioGroup>
                            <h1 className={Styles.additionalCancelReasonLabel}>
                                {
                                    cancellationReason === reasons[1]
                                    ? FEATURELABEL
                                    : cancellationReason === reasons[4]
                                        ? SYSTEMLABEL
                                        : DEAFAULTLABEL
                                }
                            </h1>

                            <textarea ref={noteRef} onChange={(e)=>{ setCancellationReasonAdditional(e.target.value) }} className={Styles.textarea}>{cancellationReasonAdditional}</textarea>
                            <div className={Styles.buttonGroup}>
                                <Button onClick={()=>{
                                    if(window.showNotification){
                                        window.showNotification("SUCCESS", "Thank you for staying with us");
                                    }
                                    history.push("/");
                                }} disabled={cancelling} className="ml-2 accent--bg--color accent--bg--text--color">
                                    Don't cancel. I've changed my mind.
                                </Button>
                                <Button disabled={cancelling} className="ml-2 red--bg--color red--bg--text--color" onClick={handleSubmitCancellation}>
                                    Stop asking questions. Just cancel my account.
                                </Button>
                            </div>
                            <p className={Styles.extraMessage}>If you have additional questions prior to cancelling click the live chat button at the bottom right of your screen.</p>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default App;


