import { useEffect, useRef, useState } from "react";
import { transactionDetailsWithInfo } from "../../api/billingApi";
import CoreConstants from "../../constants/CoreConstants";
import Utils from "../../helpers/Utils";
import Loader from "../Common/Loader";
import { Preview, print } from 'react-html2pdf';
import moment from 'moment-timezone';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';
const isCreditIn = (typeFor) => {
    if (
        typeFor === CoreConstants.VIRTUAL_NUMBER ||
        typeFor === CoreConstants.PACKAGE_BUY ||
        typeFor === CoreConstants.PAY_AS_YOU_GO_BUY ||
        typeFor === CoreConstants.PACKAGE_RENEW ||
        typeFor === CoreConstants.CREDIT_ADJUSTMENT ||
        typeFor === CoreConstants.INSTANT_TOPUP ||
        typeFor === CoreConstants.ADMIN_INSTANT_TOPUP
    ) {
        return true;
    } else {
        return false;
    }
}

const DetailsPopup = (props) => {
    const [componentLoading, setComponentLoading] = useState(false);
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    const componentRef = useRef(null);
    
    const handleClose = () => {
        props.handleClose();
    }

    useEffect(() => {
        if (props.data) {
            setComponentLoading(true);

            transactionDetailsWithInfo({
                "form_data": {
                    transactionId: props.data.id
                }
            })
            .then(response => {
                setDetails(response.data.data);
            }).finally(() => {
                setComponentLoading(false);
            })
        }
    }, [props.data])
    
    const renderForVirtualNumber = () => {
        return (
            <div class="p-4 ml-2">
                <div class="subtitle-1 mb-3">
                    <p class="text-dark-blue">Buy Phone number:</p>
                    <p class="text-dark-blue-65">${props.data.credit_amount}</p>
                </div>
                <div class="subtitle-1 mb-3">
                    <p class="text-dark-blue">Number:</p>
                    <p class="text-light-blue">{props.data.note}</p>
                </div>
            </div>
        )
    }

    const renderForBillingAdjustment = () => {
        return (
            <div class="p-4 ml-2">
                <div class="subtitle-1 mb-3">
                    <p class="text-dark-blue">USD Amount:</p>
                    <p class="text-dark-blue-65">${props.data.credit_amount}</p>
                </div>
                <div class="subtitle-1 mb-3">
                    <p class="text-dark-blue">Note:</p>
                    <p class="text-light-blue">{props.data.note}</p>
                </div>
            </div>
        )
    }

    const renderForPackageDetails = () => {
        return (
            <div class="p-4 ml-2">
                {
                    details && typeof details.packageDetails !== 'undefined' && details.packageDetails && (
                        <div class="subtitle-1 mb-3">
                            <p class="text-dark-blue">Package Name:</p>
                            <p class="text-dark-blue-65">${details.packageDetails.name}</p>
                        </div>
                    )
                }
                <div class="subtitle-1 mb-3">
                    <p class="text-dark-blue">Package Price:</p>
                    <p class="text-dark-blue-65">${props.data.credit_amount}</p>
                </div>
                <div class="subtitle-1 mb-3">
                    <p class="text-dark-blue">Note:</p>
                    <p class="text-light-blue">{props.data.note}</p>
                </div>
            </div>
        )
    }

    const handleDownloadPdf = async (e) => {
        e.preventDefault();
        setLoading(true); 

        const canvas = await html2canvas(document.getElementById('jsx-template'), {
            scale: 2,
            useCORS: true,
            scrollY: -window.scrollY
        });

        const imgData = canvas.toDataURL("image/png");

        // A4 dimensions in points
        const fixedWidth = 595;
        const fixedHeight = 842; 

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([fixedWidth, fixedHeight]);

        const pngImage = await pdfDoc.embedPng(imgData);

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        let width, height;

        if (imgWidth / imgHeight > fixedWidth / fixedHeight) {
            width = fixedWidth;
            height = (imgHeight * fixedWidth) / imgWidth;
        } else {
            height = fixedHeight;
            width = (imgWidth * fixedHeight) / imgHeight;
        }

        page.drawImage(pngImage, {
            x: 0, 
            y: fixedHeight - height, 
            width: width,
            height: height,
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = `transaction_${props.data.id}.pdf`;
        link.click();

        URL.revokeObjectURL(url);
        setLoading(false); 
    };

    const renderDetails = () => {
        if (props.data.type_for === CoreConstants.VIRTUAL_NUMBER) {
            return renderForVirtualNumber();
        } else if (isCreditIn(props.data.type_for) && props.data.type_for === CoreConstants.CREDIT_ADJUSTMENT || props.data.type_for === CoreConstants.INSTANT_TOPUP || props.data.type_for === CoreConstants.ADMIN_INSTANT_TOPUP) {
            return renderForBillingAdjustment();
        } else if (props.data.type_for === CoreConstants.PACKAGE_RENEW || props.data.type_for === CoreConstants.PACKAGE_BUY) {
            return renderForPackageDetails();
        } else {
            return (
                <>
                    {
                        componentLoading || !details ? (
                            <div style={{paddingTop: 20, paddingLeft: 100}}>
                                <Loader/>
                            </div>
                        ) : (
                                <div class="p-4 ml-2">
                                
                                <div class="subtitle-1 mb-3">
                                    <p class="text-dark-blue">Contact:</p>
                                    <p class="text-dark-blue-65">{details.contactName}</p>
                                </div>
                                <div class="subtitle-1 mb-3">
                                    <p class="text-dark-blue">User:</p>
                                    <p class="text-light-blue">{(typeof details.userDetails && details.userDetails) ? details.userDetails.full_name : ''}</p>
                                </div>
                                <div class="subtitle-1 mb-3">
                                    <p class="text-dark-blue">Sent From:</p>
                                    <p class="text-light-blue">{details.conversationOrigin}</p>
                                </div>
                                <div class="subtitle-1 mb-3">
                                    <p class="text-dark-blue">Message Content:</p>
                                    <p class="text-black"><div dangerouslySetInnerHTML={{ __html: details?.message || '' }}></div></p>
                                </div>
                                <div class="subtitle-1 mb-3">
                                    <p class="text-dark-blue">Amount Of Credit(USD):</p>
                                    <p class="text-light-blue">${props.data.credit_amount}</p>
                                </div>
                            </div>
                        )
                    }
                </>
            )
        }
    }

    let usageDate = '';

    if(props.data.local_created_at != undefined && props.data.local_created_at.date_time != undefined){
        let userTimeZone = Utils.getAccountData('userTimezoneRaw');

        usageDate = moment.utc(props.data.local_created_at.date_time).tz(userTimeZone).format('MM-DD-YY h:mm:ss');


    }

    return (
       <>
            <div id="view_details_modal" class="modal mr-0" style={{ display: 'block' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '6px', background: 'linear-gradient(to right, #3b7ef3  37.5%, white 37.5%)', position: 'relative' }}>
                    <div className="text-container d-flex align-items-center" style={{ flex: 1, justifyContent: 'center' }}>
                        <p className="transaction text-dark-blue heading-5 mb-0" style={{ transform: 'translateX(26px)' }}>Transaction details</p>
                    </div>
                    <span className="modal-close" onClick={handleClose} style={{ position: 'absolute', right: '20px' }}>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#FF264A" />
                            <path d="M17.1223 19.1368L19.1328 17.125L8.87515 6.86052L6.86464 8.87237L17.1223 19.1368Z" fill="white" />
                            <path d="M8.87516 19.1395L19.1328 8.875L17.1223 6.86315L6.86464 17.1276L8.87516 19.1395Z" fill="white" />
                        </svg>
                    </span>
                </div>

                <div className="text-center py-3 view_details_download_btn" style={{ background: 'linear-gradient(to right, #3b7ef3 37.5%, white 37.5%)', marginBottom: 0 }}>
                    <a href='#' onClick={handleDownloadPdf} className="white-text subtitle-1 accent--bg--color py-3 px-4 radius-5 d-inline-flex justify-content-center align-items-center">
                        Download as pdf
                    </a>
                </div>
                <div className="d-flex" ref={componentRef} id="jsx-template">
                    {/* This ID targets the content for the PDF */}
                    <div className="view_details_modal_left bg-light-blue d-flex flex-column justify-content-center align-items-center"
                        >
                        <p className="heading-4 email white-text d-flex align-items-center">
                            {Utils.getTextForType(props.data.type_for)}
                        </p>
                        <p className="weight-semi-bold heading-3 white-text currency">${props.data.credit_amount}</p>
                        <p className="weight-semi-bold heading-6 text-light-green">{usageDate}</p>
                    </div>
                    <div className="view_details_modal_right">
                        {renderDetails()}
                    </div>
                </div>

    
            </div>
        
    </>
    )
}

export default DetailsPopup;