import { Fragment, useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import TablePagination from '@material-ui/core/TablePagination';
import styled from "styled-components";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useIsMounted } from "../../../hooks/IsMounted";
import { currentPackageHistoryList ,archievePackgeListApi} from "../../../api/billingApi";
import Loader from "../../Common/Loader";
import DetailsPopup from "../DetailsPopup";
import Utils from "../../../helpers/Utils";
import moment from 'moment-timezone';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArchiveIcon from '@material-ui/icons/Archive';

const UsageHistory = (props) => {
    const isMounted = useIsMounted();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [scrolling, setScrolling] = useState(false);

    const scrollingDiv = useRef();


    const [sortType, setSortType] = useState('desc');
    const [sortBy, setSortBy] = useState('id');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [dataForModal, setDataForModal] = useState(null);
    const perPage = 20;

    const [historyType, setHistoryType] = useState('current')


    useEffect(() => {
        loadData(page);
    }, [perPage,sortType, debouncedQuery]);

    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]);


    const loadData = (nextPage,_clearPrev = false) => {
        currentPackageHistoryList({
            "form_data": {
                page: nextPage,
                per_page: perPage,
                sort_by: sortBy,
                sort_type: sortType,
                query: debouncedQuery
            }
        })
        .then(response => {

            let tempData = _clearPrev ? [] : [...data];

            response.data.data.data.forEach(element => {
                tempData.push(element);
            });

            setData(tempData);
            setPage(nextPage);
            setTotal(response.data.data.total);

            if (response.data.data.next_page_url) {
                setHasMore(true);
                setPage(nextPage)
            } else {
                setHasMore(false);
            }

        }).finally(() => {
            setLoading(false);
            setScrolling(false);
        });
    }

    const renderData = () => {
        if (loading) {
            return (
                <tr>
                    <td colspan="5">
                        <div style={{paddingTop: 50}}>
                            <Loader/>
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="5">
                        <div style={{paddingTop: 50}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item,index) => {
                let typeFor = '';

                if (item.conversion_type == 2 && item.type_for == 1) {
                    typeFor = 'SMS Notification';
                } else if (item.conversion_type == 2 && item.type_for == 4) {
                    typeFor = 'Email Notification';
                } else {
                    typeFor = Utils.getTextForType(item.type_for);
                }

                let type = item.type == 1 ? "CREDIT" : "DEBIT";

                let details = false;
                let myJson = null;
                if (item.contact_info && item.contact_info != '') {
                    try{
                        if(item.contactInfo !== undefined && item.contactInfo != null){
                            myJson = JSON.parse(item.contact_info.replace(/&quot;/g, '"'));
                        }
                    }
                    catch(e){
                        console.log(e)
                    }                }

                if (item.type == 1 || (myJson && myJson.has_conversion != null || item.type_for != 113 || item.type_for != 112)) {
                    details = true;
                }

                let usageDate = '';

                if(item.local_created_at != undefined && item.local_created_at.date_time != undefined){
                    let userTimeZone = Utils.getAccountData('userTimezoneRaw');

                    usageDate = moment.utc(item.local_created_at.date_time).tz(userTimeZone).format('MM-DD-YY h:mm:ss');


                }

                return (
                    <tr>
                        <td class="pl-5">
                            <p class="text-dark-blue-65 subtitle-1 d-flex justify-content-start align-items-center">
                                {/* {typeFor ? typeFor.replace(' ','') : ''} */}
                                {typeFor ? typeFor : ''}
                            </p>
                        </td>
                        <td>
                            <p class="heading-6 text-light-gray-2">${ (item.credit_amount.endsWith('.0000') ? `${item.credit_amount.slice(0, -4)}00` : item.credit_amount)   }</p>
                        </td>
                        <td>
                            <p class="subtitle-1 text-light-gray-2">{usageDate}
                            </p>
                        </td>
                        <td>
                            <span class={`${type === 'CREDIT' ? 'bg-light-green black-text' : 'bg-red white-text'} subtitle-1 radius-5  text-center py-2 px-3`}>{type}</span>
                        </td>
                        <td>
                            {
                                details && (
                                    <a 
                                        href="!#" 
                                        class="bg-light-blue subtitle-1 radius-5 white-text text-center accent--bg--color"
                                        onClick={e => {
                                            e.preventDefault();
                                            setDataForModal(item);
                                            setModalVisible(true);
                                        }}
                                    >Details</a>
                                )
                            }
                        </td>
                    </tr>
                )
            })
        }
    }

    const loadMore = (e) => {
        if(hasMore && !scrolling && Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)) {
            setScrolling(true);
            let currentPage = page + 1;
            if(historyType === 'current'){
                setTimeout(() => {
                    loadData(currentPage);
                }, 500);
            }
            else{
                setTimeout(() => {
                    getArchiveHistory(currentPage);
                }, 500);
            }
        }
    }

    const handleArchiveHistory = () =>{
        setLoading(true);
        setPage(1);
        getArchiveHistory(1)  
        setHistoryType('archive')      
    }

    const handleCurrentHistory = () =>{
        setLoading(true);
        setPage(1);
        loadData(1); 
        setHistoryType('current')      
    }

    const getArchiveHistory = (currentPage = page) => {
        archievePackgeListApi({
            "form_data": {
                page: currentPage,
                per_page: perPage,
                
                sort_by: sortBy,
                sort_type: sortType,
                query: debouncedQuery
            }
        })
        .then(response => {

            let tempData =  [...data];

            response.data.data.data.forEach(element => {
                tempData.push(element);
            });

            setData(tempData);
            setTotal(response.data.data.total);

            if (response.data.data.next_page_url) {
                setHasMore(true);
                setPage(currentPage)
            } else {
                setHasMore(false);
            }

        }).finally(() => {
            setLoading(false);
            setScrolling(false);
        });
    }

    const downloadCsv=()=>{
        var data = [];
        var rows = document.querySelectorAll("table tr");
                
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll("td, th");
                    
            for (var j = 0; j < cols.length; j++) {
                if(j < 2){
                    row.push(cols[j].innerText);

                }
                if(j ===2){
                    row.push(cols[j].innerText + ' '+cols[j+1].innerText );

                }
            }
                    
            data.push(row.join(",")); 		
        }

        downloadCSVFile(data.join("\n"), "1");
    }

    function downloadCSVFile(csv, filename) {
        var csv_file, download_link;
    
        csv_file = new Blob([csv], {type: "text/csv"});
    
        download_link = document.createElement("a");
    
        download_link.download = filename;
    
        download_link.href = window.URL.createObjectURL(csv_file);
    
        download_link.style.display = "none";
    
        document.body.appendChild(download_link);
    
        download_link.click();
    }

    return (
        <Fragment>
            <div id="usages__history" class="col s12 bg-gray-light p-0 active scrollable_table__height__calc">
                <div class="white radius-10 usage_history_top d-flex align-items-center justify-content-between px-3 mt-3">
                    <div className="billing__archive__package">
                        {historyType === "current" &&
                            <div className="billing__archive__package__inner"  onClick={handleArchiveHistory} >
                                <ArchiveIcon />
                                Archive History
                            </div>
                        }

                        {historyType === "archive" &&
                            <div className="billing__archive__package__inner"  onClick={handleCurrentHistory}>
                                <AccessTimeIcon />
                                Current History
                            </div>
                        }
                        
                    </div>
                    <div class="usage_search_field">
                        <input type="text" class="radius-5 common__input__field pl-3 m-0" placeholder="Search" onChange={(e) => setQuery(e.target.value)}/>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="#3C7EF3"></path>
                        </svg>
                    </div>
                </div>
                <p style={{display:'none'}} onClick={()=>downloadCsv()}>Download</p>

                <div class={props.isShowTopPart ? "scrollable_table__close scrollable_table" : "scrollable_table__open scrollable_table"} onScroll={(e) => loadMore(e)} ref={scrollingDiv} >
                    <table class="usage_history_table" >
                        <thead>
                            <tr>
                                <td class="pl-5">
                                    <p class="text-dark-blue heading-6">Type</p>
                                </td>
                                <td>
                                    <p class="text-dark-blue heading-6">Cost ($)</p>
                                </td>
                                <td>
                                    <p class="text-dark-blue heading-6">Date</p>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                        <p class="text-dark-blue heading-6">Transaction Type</p>
                                    </div>
                                </td>
                                <td>
                                    <p class="text-dark-blue heading-6">Details</p>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {renderData()}
                            {
                                 scrolling && (
                                               <div className="load-more-inbox-thread">Loading more...</div>
                                             )
                             }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                modalVisible && (
                    <DetailsPopup
                        handleClose={() => {
                            setModalVisible(false);
                            setDataForModal(null);
                        }}
                        data={dataForModal}
                    />
                )
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.billingReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsageHistory);