import React, {useEffect, useState} from 'react';
import Styles from "./Style.module.css";
import {useHistory} from "react-router-dom";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";

const App = (props) => {
    const history = useHistory();
    const [cancellationConfirmation, setCancellationConfirmation] = useState(null);

    useEffect(()=>{
        if (cancellationConfirmation && cancellationConfirmation == "cancel"){
            if(window.showNotification){
                window.showNotification("SUCCESS", "Thank you for staying with us");
            }
            history.push("/");
        }else if (cancellationConfirmation && cancellationConfirmation == "confirm"){
            history.push("/cancellation-request/confirm");
        }
    },[cancellationConfirmation]);

    return (
        <div className={Styles.pageWrapper}>
            <div className={Styles.innerPageWrapper}>
                <img className={Styles.dontDoThisImage} src="https://s3.us-east-1.amazonaws.com/pypepro/user/1/S5QX7Pdl1VB46EquVO7OKp76oLAJHTRD5cTdxN1d.gif" alt="Don't do this gif."/>
                <h1 className={Styles.sorryMessage}>We are sorry to see you go! 😭</h1>
                <p><span className={Styles.textStrong}>IMPORTANT: </span>If you delete your account all of your data will be permanently deleted.</p>
                <div className={Styles.selectOption}>
                    <div>
                        <h1 className={Styles.selectOptionLabel}>Select an option: </h1>
                        <RadioGroup className={Styles.selectOptions} aria-label="cancellationConfirmation" name="cancellationConfirmation" value={cancellationConfirmation} onChange={(e)=>{setCancellationConfirmation(e.target.value)}}>
                            <FormControlLabel className={Styles.cancellationConfirmationAction} value="confirm" control={<Radio />} label="I understand I'll lose all my data and I still want to cancel my account" />
                            <FormControlLabel className={Styles.cancellationConfirmationAction} value="cancel" control={<Radio />} label="I've changed my mind and I don't want to cancel" />
                        </RadioGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;