import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import './../Asset/transaction-details.css';
//moved to CDN
// import './../Asset/billing.css';
import { getTransactionDetails } from "./../../api/billingApi";


const TransactionApp = (props) => {

    useEffect(()=>{
        getTransactionDetails({
                transactionId : 11
        })
            .then(response => {
            }).finally(() => {

        });
    })

    return (
        <React.Fragment>
            <main className="main__content" id="main__content">
                <div className="usage_info_container">
                    <div className="row mt-4">
                        <div className="col s12">
                            <div
                                className="px-3 white radius-10 usage_info_header d-flex justify-content-between align-items-center mt-2">
                                <p className="text-dark-blue heading-5 m-0">Transaction details</p>
                                <a href="pdf-invoice.html"
                                   className="download__pdf__btn white-text bg-light-blue  radius-5 d-inline-flex justify-content-center align-items-center">
                                    <svg className="mr-2" width="20" height="20" viewBox="0 0 20 21" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path
                                                d="M9.41333 16.9226C9.41349 16.9227 9.41364 16.9229 9.4138 16.923C9.43306 16.9422 9.45333 16.9605 9.47439 16.9778C9.48403 16.9857 9.49423 16.9926 9.50415 17C9.51599 17.0089 9.52759 17.0181 9.53989 17.0263C9.55177 17.0343 9.56407 17.0411 9.57626 17.0484C9.58743 17.0551 9.59833 17.0621 9.60982 17.0682C9.62224 17.0748 9.63501 17.0805 9.64771 17.0864C9.65966 17.0921 9.67146 17.0981 9.68372 17.1032C9.69603 17.1082 9.70861 17.1124 9.72111 17.1168C9.73427 17.1216 9.74724 17.1266 9.76068 17.1307C9.77318 17.1345 9.78587 17.1372 9.79853 17.1404C9.81224 17.1439 9.82579 17.1477 9.83978 17.1505C9.85443 17.1534 9.86919 17.1551 9.88392 17.1572C9.89607 17.1589 9.90806 17.1613 9.92036 17.1625C9.97505 17.1679 10.0302 17.1679 10.0849 17.1625C10.0972 17.1613 10.1092 17.1589 10.1213 17.1572C10.136 17.1551 10.1508 17.1534 10.1654 17.1505C10.1794 17.1477 10.193 17.1439 10.2067 17.1404C10.2193 17.1372 10.232 17.1345 10.2445 17.1307C10.258 17.1266 10.271 17.1216 10.2841 17.1168C10.2966 17.1123 10.3092 17.1082 10.3215 17.1032C10.3338 17.0981 10.3456 17.0921 10.3575 17.0864C10.3702 17.0805 10.383 17.0748 10.3954 17.0682C10.4069 17.0621 10.4178 17.055 10.429 17.0484C10.4411 17.0411 10.4534 17.0343 10.4653 17.0263C10.4776 17.0181 10.4892 17.0089 10.5011 17C10.511 16.9926 10.5212 16.9857 10.5308 16.9778C10.5519 16.9605 10.5722 16.9423 10.5914 16.923C10.5916 16.9229 10.5917 16.9227 10.5919 16.9226L16.4252 11.0893C16.7506 10.7638 16.7506 10.2362 16.4252 9.91074C16.0998 9.58531 15.5721 9.58531 15.2467 9.91074L10.8359 14.3215V1.33332C10.8359 0.873086 10.4628 0.5 10.0026 0.5C9.54236 0.5 9.16927 0.873086 9.16927 1.33332V14.3215L4.75853 9.91074C4.4331 9.58531 3.90544 9.58531 3.58001 9.91074C3.25458 10.2362 3.25458 10.7638 3.58001 11.0893L9.41333 16.9226Z"
                                                fill="white"/>
                                            <path
                                                d="M15.8359 18.8359H4.16926C3.70902 18.8359 3.33594 19.209 3.33594 19.6693C3.33594 20.1295 3.70902 20.5026 4.16926 20.5026H15.8359C16.2962 20.5026 16.6693 20.1295 16.6693 19.6693C16.6693 19.2091 16.2961 18.8359 15.8359 18.8359Z"
                                                fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    Download as pdf
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="white radius-10 p-3 margin-tab">
                                <div className="d-flex justify-content-between mt-1">
                                    <div className="">
                                        <p className="text-dark-blue heading-4 mb-3">Invoice</p>
                                        <p className="text-dark-blue subtitle-1">INVOICE TO : Technical Test</p>
                                        <p className="text-dark-blue subtitle-1">Phone : 18646713693</p>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center mb-3">
                                            <img className="logo_img" src="./images/small-logo.png" alt="" />
                                                <p className="text-dark-blue logo-text ml-3">Pypepro</p>
                                        </div>
                                        <p className="text-dark-blue-65 subtitle-1">INVOICE NO : 6JN6RUN3RT</p>
                                        <p className="text-dark-blue-65 subtitle-1">DATE : 21 Apr 2021, 06:03 PM</p>
                                    </div>
                                </div>
                                <table className="invoice__table">
                                    <thead>
                                    <tr className="py-3">
                                        <td className="pl-3 col s3 py-3">
                                            <p className="text-gray subtitle-1">Invoice For</p>
                                        </td>
                                        <td className="col s3 py-3">
                                            <p className="text-gray subtitle-1  text-center">Package Type</p>
                                        </td>
                                        <td className="col s3 py-3">
                                            <p className="text-gray subtitle-1  text-center">USD Amount</p>
                                        </td>
                                        <td className="col s3 py-3">
                                            <p className="text-gray subtitle-1  text-center">Amount</p>
                                        </td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="pl-3 col s3 py-3">
                                            <p className="text-dark-blue-65 subtitle-1">Developer Testing Package</p>
                                        </td>
                                        <td className="col s3 py-3">
                                            <p className="text-dark-blue-65 subtitle-1 text-center">Regular Package</p>
                                        </td>
                                        <td className="col s3 py-3">
                                            <p className="text-dark-blue-65 heading-6 text-center">$10</p>
                                        </td>
                                        <td className="col s3 py-3">
                                            <p className="text-dark-blue-65 heading-6 text-center">$0</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between">
                                    <div className="text-dark-blue subtitle-1">
                                        <p>Payment Info</p>
                                        <p className="pt-1">Accounts : Technical Test</p>
                                        <p className="pt-1">Card NUmber : **** **** **** 4242</p>
                                    </div>
                                    <div className="text-right">
                                        <p className="text-dark-blue subtitle-2 subtotal pb-2">Sub Total : <span
                                            className="text-light-blue heading-6">$ 10.00</span>
                                        </p>
                                        <p className="text-dark-blue subtitle-1 weight-semi-bold pt-1">Total : <span
                                            className="heading-5">$ 10.00</span></p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </main>
        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
}

const Transaction = connect(mapStateToProps, mapDispatchToProps)(TransactionApp);

export default Transaction;
