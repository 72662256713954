import {payNowForTrialPack} from "../api/billingApi";

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/billing`;
const profileUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`;

const Api = {
    getTransactionDetails: `${rootUrl}/get-transaction-details`,
    details: `${rootUrl}/details`,
    existingCardList: `${profileUrl}/existing-card-list`,
    activateCard: `${profileUrl}/activate-card`,
    deleteCard: `${profileUrl}/delete-card`,
    saveCard: `${profileUrl}/save-card`,
    getCardInfoSettings: `${profileUrl}/get-card-info-settings`,
    getInstantTopupSettings: `${profileUrl}/get-instant-topup-settings`,
    getInstantTopupSettingsAll: `${profileUrl}/get-instant-topup-settings-all`,
    rechargeInstantTopup: `${profileUrl}/recharge-instant-topup`,
    getRechargeSettings: `${profileUrl}/get-recharge-settings`,
    updateRechargeSettings: `${profileUrl}/update-recharge-settings`,
    removeRechargeSettings: `${profileUrl}/remove-recharge-settings`,
    currentPackageHistoryList: `${rootUrl}/current-package-history-list`,
    transactionDetailsWithInfo: `${rootUrl}/transaction-details-with-info`,
    invoiceList: `${rootUrl}/invoice-list`,
    invoiceDetails: `${rootUrl}/invoice-details`,
    payNowForTrialPack: `${rootUrl}/pay-now-for-trial-pack`,
    archievePackageList: `${rootUrl}/archive-package-history-list`,
    accountCancellation: `${rootUrl}/account-cancellation`,
}

export default Api;