import { Button } from "@material-ui/core";
import {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import Select from 'react-select';
import { getInstantTopupSettings, getInstantTopupSettingsData, rechargeInstantTopup } from "../../../api/billingApi";
import Loader from "../../Common/Loader";
import { fetchDetails } from "../../../actions/billingAction";
import { confirmAlert } from 'react-confirm-alert';
import * as Constants from "../Constants";
import CustomConfirmAlert from "../../Common/CustomConfirmAlert";


const options = [
    { value: 10, label: '$10' },
    { value: 25, label: '$25' },
    { value: 50, label: '$50' },
    { value: 75, label: '$75' },
    { value: 150, label: '$150' },
    { value: 200, label: '$200' },
    { value: 250, label: '$250' },
    { value: 500, label: '$500' },
    { value: 1000, label: '$1000' },
]

const InstantTopup = (props) => {

    const [componentLoading, setComponentLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    const [customer, setCustomer] = useState(null);
    const [value, setValue] = useState(10);
    const [selectTopup, setSelectTopup] = useState(options);

    useEffect(() => {
        loadData();
        loadTopupData();
    }, [])

    const loadData = () => {
        getInstantTopupSettings({})
        .then(response => {
            setCustomer(response.data.data);
        });
    }

    const loadTopupData = () => {
        getInstantTopupSettingsData({})
        .then(response => {
            if(response.data.data != ""){
                const op = response.data.data.value;
                const myArray = op.split(",");
                const values = [];
                myArray.map((item)=>{
                    values.push({value: item, label: ('$'+item)});
                });
                if(myArray.length>0){
                    setValue(values[0].value);
                }
                
                setSelectTopup(values);    
            }
            else{
                setSelectTopup(options);  
            }
           
        }).finally(() => {
            setComponentLoading(false);
        });
    }

    const requestAutoRecharge = () => {
        CustomConfirmAlert({
            handleConfirm : () => {
                props.setTab(Constants.TAB_AUTO_RECHARGE);
            },
            handleOnClose: () => {},
            title: 'Do you want to activate auto-recharge?',
            description: 'Auto recharge allows you to recharge your balance automatically on given balance threshold',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Yes',
            minHeight: '260px'
        });
    }

    const submitData = () => {

        CustomConfirmAlert({
            handleConfirm : async () => {
                setLoading(true);

                rechargeInstantTopup({
                    "form_data": {
                        refill_amount: value
                    }
                }).then((response) => {
                    let res = response.data
                    if(res.data.status !== undefined && res.data.status === 'error'){
                        window.showNotification('ERROR',res.data.html);
                        setLoading(false);
                        return;
                    }
                    window.showNotification('SUCCESS',response.data.message);

                    if(res.data.auto_recharge_status !== undefined && !res.data.auto_recharge_status) {
                        requestAutoRecharge();
                    }

                    props.fetchDetails()
                    setLoading(false);
                    if(window.updateCurrentCredit !== undefined){
                        window.updateCurrentCredit()
                    }
                }).catch(e=>{
                    setLoading(false);

                })
            },
            handleOnClose: () => {},
            title: 'Confirmation for Instant Top Up',
            description: 'Are you sure to recharge $'+value+'?',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Yes',
            minHeight: '220px'
        });
    }

    return (
        <Fragment>
            {
                (componentLoading || !props.user) ? (
                    <div style={{paddingTop: 50}}>
                        <Loader/>
                    </div>
                ) : (
                    <Fragment>
                        {
                            (props.isTrial) ? (
                                    <div style={{paddingTop: 50}}>
                                        <Alert severity="error">This feature is not available for you currently. You are in trial mode</Alert>
                                    </div>
                                ) :
                                (
                                    (customer && typeof customer.id !== 'undefined') ? (
                                        <div id="instant_top_up" class="col s12 mt-3">
                                            <div className="row">
                                                <div class="col s6">
                                                    <Alert severity="info">System will use your active saved card information to recharge instantly.</Alert>
                                                    <p className="mt-4 mb-2">
                                                        Recharge my account to this amount
                                                    </p>

                                                    <div>
                                                        <Select
                                                            options={selectTopup}
                                                            isClearable={false}
                                                            value={{
                                                                value: value,
                                                                label: `$${value}`,
                                                            }}
                                                            styles={{
                                                                width: '100px',
                                                                menu: provided => ({ ...provided, zIndex: 9999999, }),
                                                                valueContainer: provided => ({
                                                                    ...provided,
                                                                    width: '50px',
                                                                    
                                                                    '& > div:last-child': {
                                                                        height: '45px'               
                                                                    }
                                                                })
                                                            }}
                                                            onChange={(_selectedOption) => {setValue(_selectedOption.value)}}
                                                        />
                                                    </div>

                                                    <div className="mt-3">
                                                        <Button variant="contained" className={"accent--bg--color"} disabled={loading} onClick={submitData}>
                                                            {!loading ? 'Recharge' : 'Recharging...'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{paddingTop: 50}}>
                                            <Alert severity="error">This feature is not available for you currently. Please add card information.</Alert>
                                        </div>
                                    )
                                )
                        }
                    </Fragment> 
                )
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.billingReducer.user,
        isTrial: state.billingReducer.isTrial
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchDetails: (params) => dispatch(fetchDetails(params)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantTopup);