import { Button, FormControl, InputLabel, OutlinedInput, TextField, makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import ExitingCards from "./ExitingCards";
import Tab from '@material-ui/core/Tab';
import ChangeCard from "./ChangeCard";
import { getCardInfoSettings } from "../../../api/billingApi";
import Loader from "../../Common/Loader";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  inputText: {
    borderBottom: 'none !important',

    '& .MuiInputBase-input': {
        padding: '18px 15px 10px 14px !important',
        boxShadow: 'none !important',
        borderBottom: 'none !important',

    }
  }
}));

const ActiveCard = ({cardNumber}) => {
    const classes = useStyles();

    return (
        <Fragment>
            <table class="table">
                <tbody>
                <tr style={{borderBottom: 'none'}}>
                    {/* <th><label>Card Number</label></th>
                    <td>**** **** **** <span id="activeCard">{cardNumber}</span></td> */}
                    <td>    
                        <TextField disabled className={classes.inputText} id="activeCard" label={`Card Number **** **** **** ${cardNumber}`} variant="outlined" />
                    </td>
                </tr>
                </tbody>
            </table>
        </Fragment>
    )
}

const CardInfo = (props) => {
    const [componentLoading, setComponentLoading] = useState(true);
    const [loading, setLoading] = useState(false);
     const classes = useStyles();

    const [customer, setCustomer] = useState(null);
    const [stripePublicKey, setStripePublicKey] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        setComponentLoading(true);

        getCardInfoSettings({})
        .then(response => {
            setCustomer(response.data.data.customer);
            setStripePublicKey(response.data.data.stripePublicKey);
        }).finally(() => {
            setComponentLoading(false);
        });
    }

    return (
        <Fragment>
            {
                (componentLoading) ? (
                    <div style={{paddingTop: 50}}>
                        <Loader/>
                    </div>
                ) : (
                    <Fragment>
                        {
                            (customer && typeof customer.id !== 'undefined') ? (
                                 <div className={classes.root}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={selectedTab}
                                        onChange={(event, newValue) => setSelectedTab(newValue)}
                                        aria-label="Vertical tabs example"
                                        className={classes.tabs}
                                    >
                                        <Tab className={"card-info-side-tab"} label="ACTIVE CARD" {...a11yProps(0)} />
                                        <Tab className={"card-info-side-tab"} label="CHANGE CARD" {...a11yProps(1)} />
                                        <Tab className={"card-info-side-tab"} label="EXISTING CARDS" {...a11yProps(2)} />
                                    </Tabs>
                                    <TabPanel value={selectedTab} index={0}>
                                        <ActiveCard cardNumber={customer.card_number}/>
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={1}>
                                        {
                                            stripePublicKey ? (
                                                <ChangeCard hasCard={true} stripePublicKey={stripePublicKey} callback={() => {
                                                    setSelectedTab(0);
                                                    loadData();
                                                }}/>
                                            ) : (
                                                'Stripe info not set perfectly'
                                            )
                                        }
                                    </TabPanel>
                                    <TabPanel value={selectedTab} index={2}>
                                        <ExitingCards callback={loadData}/>
                                    </TabPanel>
                                </div>
                            ) : (
                                <ChangeCard hasCard={false} stripePublicKey={stripePublicKey} callback={loadData}/>
                            )
                        }
                    </Fragment> 
                )
            }
        </Fragment>
    )
}

export default CardInfo;