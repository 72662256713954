import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { TAB_USAGE_HISTORY } from "../Constants";
import moment from 'moment';
import {Alert} from "@material-ui/lab";
import {Button} from "@material-ui/core";
import { payNowForTrialPack } from "../../../api/billingApi";
import Utils from "../../../helpers/Utils";
import {useHistory} from "react-router-dom";

const TopPart = (props) => {

    const [loading, setLoading] = useState(false);
    const [btnTitle, setBtnTitle] = useState('Upgrade My Account');
    const history = useHistory();

    let expireDate = '';

    if(props.userPackage){
        expireDate = moment(props.userPackage.expire_date).format('MM-DD-YYYY');
    }

    const payNow = () => {
        if(loading){
            return;
        }
        setLoading(true);
        setBtnTitle('Processing...');
        payNowForTrialPack({})
            .then(response => {
                if(response.data !== undefined && response.data.status !== undefined && response.data.status === 'success'){
                    setBtnTitle('Success');
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                }else{
                    Utils.showNotification('something went wrong', 'error', () => {
                        window.location.reload();
                    });
                }
            }).finally(() => {
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }


    return (
        <div class="row">
            <div class="col xl6 s12">
                <div class="white radius-10  p-3 billing__card">
                    <div className="d-flex justify-content-between">
                    <div class="pr-3">
                        <p class="current_usage text-dark-blue m-0 subtitle-1">Current Package :
                        </p>
                        <p class="testing_package heading-6 text-light-blue m-0 weight-medium">{(props.userPackage && typeof props.userPackage.package !== 'undefined') ? props.userPackage.package?.name : ''} package</p>
                        {
                            (props.userSettings && typeof props.userSettings.recurrent !== 'undefined' && parseInt(props.userSettings.recurrent) === 1) ? (
                                <p class="text-dark-blue-65 subtitle-2 auto_bill_message my-3 pr-4">
                                    <i>*Automatic billing is currently enabled. Your card on file will be automatically charged on the next billing date</i>
                                </p>
                            ) : (
                                <p class="text-dark-blue-65 subtitle-2 auto_bill_message my-3 pr-4">
                                    <i>Your auto bill is <b>turned off</b>. Please turn it on to continue your
                                        subscription automatically.</i>
                                </p>
                            )
                        }
                        
                        {/*<p class="subtitle-1 text-dark-blue">Subscription package:</p>*/}
                        {/*<p class="text-light-blue m-0 subtitle-1 d-flex align-items-center">$<span*/}
                        {/*        class="heading-6"> {props.userPackage?.price}</span><span*/}
                        {/*        class="text-dark-blue-65 subtitle-2">/{props.userPackage?.life_line}</span>*/}
                        {/*</p>*/}
                    </div>
                    {
                        (props.userSettings && typeof props.userSettings.recurrent !== 'undefined' && parseInt(props.userSettings.recurrent) === 1) ? (
                            <div>
                                <p class="weight-semi-bold text-dark-blue heading-6">Next Billing Date</p>
                                <p>{expireDate}</p>
                                <p className="weight-semi-bold text-dark-blue heading-6">Next Bill Amount</p>
                                <p>$ {props.userPackage?.price} + additional fees for each sub user</p>
                            </div>
                        ) : (
                            <div>
                                <p class="weight-semi-bold text-dark-blue heading-6">Subscription will expire</p>
                                <p class="text-dark-blue subtitle-2 mt-1">on {props.userPackage ? expireDate : ''}</p>
                            </div>
                        )
                    }
                </div>
                    {(props.isTrial || Utils.getAccountData("agencyId") === 1) ? (
                    <div className="pt-2">
                        {
                                <div className={"trial-box"}>
                                    {(props.isTrial) ? (
                                        <>
                                            <p>You are currently on a free trial!<span className={" expireDate text-danger"}>Trial expires:{expireDate}</span></p>
                                            <p><small>During your free trial you are unable to register your phone numbers for A2P/10DLC</small></p>
                                            <p><small>Your access to certain features may also be limited. To upgrade or cancel your account select an option below</small></p>
                                        </>
                                    ) : (
                                        <span>If you delete your account all of your data will be permanently deleted.</span>
                                    )}

                                    <div className={"upgrade-btn"}>
                                        {(props.isTrial) ? (
                                        <Button className={"ml-2 accent--bg--color accent--bg--text--color"} disabled={loading} onClick={payNow}>
                                            {btnTitle}
                                        </Button>
                                        ) : (<div/>)}
                                        <Button className={"ml-2 red--bg--color red--bg--text--color"} disabled={loading} onClick={()=>{
                                            history.push("/cancellation-request");
                                        }}>
                                            Cancel My Account
                                        </Button>
                                    </div>
                                </div>

                        }
                    </div>
                    ) : (<></>)}

                </div>


            </div>
            <div class="col xl6 s12">
                <div class="white radius-10 d-flex justify-content-between p-3 balance__card">
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="text-dark-blue heading-6 weight-semi-bold">Total Available Balance :</p>
                        <p class="heading-3 weight-bold text-light-blue balance__currency">$ {parseFloat(props.totalCredits).toFixed(2)}</p>
                    </div>
                    <div class="d-flex justify-content-between align-items-end">
                        <div class="progress__bar_container mr-3">
                            <div class="d-flex justify-content-between text-dark-blue heading-6">
                                <p class="">0</p>
                                <p class="text-right">{props.userPackage ? parseFloat(props.userPackage.current_credit).toFixed(2) : 0}/{props.userPackage ? props.userPackage.credit_amount : 0}</p>
                            </div>
                            <div class="progress__bar radius-10 d-flex">
                                <div class="progress__field radius-10"></div>
                            </div>
                            <p class="text-gray subtitle-2">All Customs Value</p>
                        </div>
                        <a href="#view_details_modal"
                            onClick={e => {
                                e.preventDefault();
                                props.setTab(TAB_USAGE_HISTORY);
                            }}
                            class="modal-trigger view_details_btn white-text bg-light-blue subtitle-1 radius-5 d-flex justify-content-center align-items-center accent--bg--color">
                            <span class="white circle  d-flex justify-content-center align-items-center mr-2">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.0007 5.42578C6.58006 5.42578 5.42578 6.58006 5.42578 8.0007C5.42578 9.42135 6.58006 10.5756 8.0007 10.5756C9.42135 10.5756 10.5756 9.42135 10.5756 8.0007C10.5756 6.58006 9.42135 5.42578 8.0007 5.42578ZM7.80536 7.2371C7.48572 7.2371 7.21935 7.50348 7.21935 7.82312H6.36696C6.38472 7.02401 7.02401 6.38472 7.80536 6.38472V7.2371Z" />
                                    <path
                                        d="M15.8135 7.46865C14.9434 6.38541 11.8357 2.85156 8 2.85156C4.16426 2.85156 1.0566 6.38541 0.18646 7.46865C-0.0621532 7.77054 -0.0621532 8.21449 0.18646 8.53414C1.0566 9.61738 4.16426 13.1512 8 13.1512C11.8357 13.1512 14.9434 9.61738 15.8135 8.53414C16.0622 8.23225 16.0622 7.7883 15.8135 7.46865ZM8 11.7306C5.94007 11.7306 4.27081 10.0613 4.27081 8.0014C4.27081 5.94146 5.94007 4.27221 8 4.27221C10.0599 4.27221 11.7292 5.94146 11.7292 8.0014C11.7292 10.0613 10.0599 11.7306 8 11.7306Z" />
                                </svg>

                            </span>
                            View Details
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        userPackage: state.billingReducer.userPackage,
        userSettings: state.billingReducer.userSettings,
        totalCredits: state.billingReducer.totalCredits,
        isTrial: state.billingReducer.isTrial,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopPart);