import { Fragment, useEffect, useState } from "react"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ChangeCardStripeForm from "./ChangeCardStripeForm";
import Loader from "../../Common/Loader";


const ChangeCard = (props) => {
    const stripePromise = loadStripe(props.stripePublicKey);
    const [componentLoading, setComponentLoading] = useState(false);

    return (
        <Fragment>
            {
                props.stripePublicKey ? (
                    <Fragment>
                        {
                            componentLoading ? (
                                <div style={{paddingTop: 20, paddingLeft: 100}}>
                                    <Loader/>
                                </div>
                            ) : (
                                <div style={props.hasCard==false ? {maxWidth: '70%'} : {maxWidth: '700px'}} className={props.hasCard==false ? "pl-5 pt-5" : ""}>
                                    <Elements stripe={stripePromise}>
                                        <ChangeCardStripeForm callback={props.callback}/>
                                    </Elements>
                                </div>
                            )
                        }
                    </Fragment>
                ) : (
                    'Stripe info not set perfectly'
                )
            }
        </Fragment>
    )
}

export default ChangeCard;